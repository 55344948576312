export class Mode {
    number: number;
    name: string;

    constructor(number: number, name: string) {
        this.number = number;
        this.name = name;
    }

    equals(otherMode: Mode) {
        if (!(otherMode instanceof Mode)) {
            return false;
        }
        return this.number === otherMode.number;
    }
}

const modes = [
    "RESERVATION",
    "NOT_USED_ACC",
    "META_RELATION",
    "HPERIOD",
    "SELECT",
    "INACTIVE",
    "NOT_USED_WEEK",
    "STATISTICS",
    "CLUSTER",
] as const;

type TModes = (typeof modes)[number];

type TFunctionMode = {
    [m in TModes]: Mode;
} & {
    get: (number: number) => Mode;
};

const FunctionMode: TFunctionMode = {} as any;

FunctionMode.get = function (number: number | string) {
    const intVal = parseInt(number as string, 10) || 0;
    return FunctionMode[modes[intVal]];
};

export default (function () {
    modes.forEach((mode, index) => {
        FunctionMode[mode] = new Mode(index, `FUNC_MODE_${mode}`);
    });
    return FunctionMode;
})();
