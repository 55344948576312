import API from "../lib/TimeEditAPI";
import { McFluffy } from "./McFluffy";
import { Model } from "./Model";
import { Reservation } from "./Reservation";
import ReservationStatus from "../lib/ReservationStatus";
import ReservationConstants from "../lib/ReservationConstants";
import Log from "../lib/Log";
import _ from "underscore";
import { ClusterKind } from "../lib/EntryConstants";
import { Entry } from "./Entry";

const SNAPSHOT_LIMIT = 20;

export class Selection extends Model {
    fluffy: McFluffy | null;
    mode: any;
    editedEntry: Entry;
    timeLimit: any;
    availableObjects: boolean;
    selectNextItem: boolean;
    reservations: number[];
    groups: number[];
    isGroupMode: boolean;
    length: boolean;
    obstacleTextTypes: number[];
    snapshots: McFluffy[];
    currentSnapshot: number;
    duration: boolean;

    static DEFAULT = 0;
    static EDIT = 1;
    static WAITING_LIST = 2;

    constructor() {
        super("Selection");
        this.fluffy = null;
        this.mode = Selection.DEFAULT;
        this.editedEntry = null;
        this.timeLimit = null;
        this.availableObjects = true;
        this.selectNextItem = false;
        this.reservations = [];
        this.groups = [];
        this.isGroupMode = false;
        this.length = false; // Holds length of a selected reservation on the waiting list
        this.obstacleTextTypes = [];
        this.snapshots = [];
        this.currentSnapshot = -1;
        this.duration = false; // Holds duration from activity manager
    }

    createFluffy(templateId, layerId, templateKind, callback) {
        let baseFluffy = McFluffy.create();
        baseFluffy.layer = layerId || 0;
        if (templateKind !== null) {
            baseFluffy.templateKind = templateKind;
        }
        if (templateId !== null) {
            baseFluffy.templateGroupId = templateId;
        }

        baseFluffy = baseFluffy.toJson();
        API.updateMcFluffy(baseFluffy, (result) => {
            const newFluffy = McFluffy.create(result);
            callback(this.setFluffy(newFluffy));
        });
    }

    getCurrentReservationId(): number {
        return this.reservations.length > 0 ? this.reservations[0] : 0;
    }

    setLayer(layerId) {
        return this.immutableSet({
            fluffy: this.fluffy?.setLayer(layerId),
        });
    }

    setGroups(groupIds) {
        return this.immutableSet({
            groups: groupIds,
        });
    }

    enableAddMode(groupIds, keepReservations = false) {
        return this.immutableSet({
            groups: groupIds,
            isGroupMode: true,
            reservations: keepReservations ? this.reservations : [],
        });
    }

    toggleAddMode(groupIds: number[]) {
        return this.immutableSet({
            groups: groupIds,
            isGroupMode: !this.isGroupMode,
        });
    }

    clearBeginEndTimes() {
        return this.immutableSet({
            fluffy: this.fluffy?.immutableSet({
                beginTime: undefined,
                endTime: undefined,
            }),
        });
    }

    clearReservations() {
        return this.disableEditMode().immutableSet({
            reservations: [],
            fluffy: this.fluffy?.immutableSet({
                reservation: undefined,
                beginTime: undefined,
                endTime: undefined,
            }),
        });
    }

    belongsToGroup() {
        return this.groups.length > 0;
    }

    getGroupIds() {
        return this.groups;
    }

    setFluffy(fluffy: McFluffy, clearReservations = this.mode === Selection.DEFAULT) {
        const newTypes = fluffy.getTypeIds();
        const obstacleTextTypes = this.obstacleTextTypes.filter((textType) =>
            _.contains(newTypes, textType)
        );
        let snapshots: any[] = ([] as any[]).concat(this.snapshots);
        let currentSnapshot = snapshots.indexOf(fluffy);
        if (currentSnapshot === -1 && !_.isNullish(fluffy)) {
            if (
                snapshots.length === 0 ||
                !_.isEqual(fluffy.toJson(), snapshots[this.currentSnapshot].toJson())
            ) {
                currentSnapshot = this.currentSnapshot + 1;
                snapshots = _.head(snapshots, currentSnapshot);
                snapshots.push(fluffy);
            } else {
                currentSnapshot = this.currentSnapshot;
            }
        }
        if (snapshots.length > SNAPSHOT_LIMIT) {
            snapshots = _.last(snapshots, SNAPSHOT_LIMIT);
            currentSnapshot = SNAPSHOT_LIMIT - 1;
        }

        let groups = this.groups;
        let reservations = this.reservations;
        if (clearReservations && !this.isGroupMode) {
            groups = [];
            reservations = [];
        }

        return this.immutableSet({
            fluffy,
            snapshots,
            currentSnapshot,
            obstacleTextTypes,
            reservations,
            groups,
        });
    }

    setDuration(duration) {
        if (!duration) {
            return this;
        }
        return this.immutableSet({
            duration,
        });
    }

    setSelectNextItem(selectNextItem) {
        return this.immutableSet({
            selectNextItem,
        });
    }

    isEditRequest() {
        return this.editedEntry.status === ReservationStatus.C_REQUESTED.id;
    }

    isEditMode() {
        return this.mode === Selection.EDIT;
    }

    isWaitingListMode() {
        return this.mode === Selection.WAITING_LIST;
    }

    hasTimeLimit() {
        return this.timeLimit !== null;
    }

    setTimeLimit(begin, end) {
        let limit = { begin, end };
        if (!end) {
            limit = getTimeLimit(begin);
        }
        return this.immutableSet({
            timeLimit: limit,
        });
    }

    removeTimeLimit() {
        return this.immutableSet({
            timeLimit: null,
        });
    }

    setAvailableObjects(availableObjects) {
        return this.immutableSet({ availableObjects });
    }

    enableEditMode(editedEntry, isGroupMode = false) {
        if (!editedEntry) {
            return this;
        }
        const iGM = isGroupMode || this.isGroupMode;
        return this.immutableSet({
            mode: Selection.EDIT,
            editedEntry,
            editedFluffy: this.fluffy,
            timeLimit: getTimeLimit(editedEntry),
            availableObjects: true,
            isGroupMode: iGM,
            groups: iGM ? this.groups : editedEntry.groups, // If already in group mode, keep those groups
        });
    }

    disableEditMode() {
        return this.immutableSet({
            mode: Selection.DEFAULT,
            editedEntry: null,
            editedFluffy: null,
            timeLimit: null,
        });
    }

    disableWaitingListMode() {
        return this.immutableSet({
            mode: Selection.DEFAULT,
            length: false,
        });
    }

    finishEditMode(
        closeRequest,
        allowAvailabilityOverlap = false,
        successCallback: (reservationIs: number[]) => void = _.noop as any,
        errorCallback: (e: Error) => void = _.noop as any
    ) {
        let saveFluffy = this.fluffy;
        if (closeRequest && this.isEditRequest()) {
            saveFluffy = McFluffy.create(saveFluffy?.toJson());
            saveFluffy.status = [ReservationStatus.C_REJECTED.id];
        } else {
            // Always allow incomplete reservations in edit mode
            saveFluffy = saveFluffy!.immutableSet({ allowIncomplete: true });
        }
        Reservation.save(
            this.editedEntry,
            saveFluffy,
            (result, reservationIds) => {
                if (result instanceof Error) {
                    if (
                        (result as any).code !==
                        ReservationConstants.ERROR_AVAILABILITY_OVERLAP_POSSIBLE
                    ) {
                        Log.warning(result.message);
                    }
                    errorCallback(result);
                } else {
                    successCallback(reservationIds);
                }
            },
            true,
            allowAvailabilityOverlap, // Allow availability overlap
            false
        );
    }

    setFluffyFields(fields) {
        return this.setFluffy(this.fluffy!.setFields(fields), false);
    }

    isObstacleTextType(typeId: number) {
        return _.contains(this.obstacleTextTypes, typeId);
    }

    addObstacleTextType(typeId: number) {
        return this.immutableSet({ obstacleTextTypes: this.obstacleTextTypes.concat(typeId) });
    }

    removeObstacleTextType(typeId) {
        return this.immutableSet({
            obstacleTextTypes: this.obstacleTextTypes.filter((id) => id !== typeId),
        });
    }

    setReservation(
        inIds: number[],
        callback,
        modify = false,
        isWaitingList = false,
        length = false,
        isNewReservation = false,
        clusterKind = ClusterKind.NONE
    ) {
        const reservationIds: number[] = _.asArray(inIds) as any;
        if (reservationIds.length === 0) {
            return callback(this, true);
        }
        let resultSetReservationToMcFluffy: any = false;
        let resultGetReservations: any = false;
        API.getTemplateGroups(this.fluffy?.templateKind.number, (templateGroups) => {
            const templateGroupIds = isWaitingList
                ? [0]
                : templateGroups.parameters[0].map((group) => group.id);

            _.runAsync(
                [
                    (done) => {
                        const inFluffy = this.fluffy?.toJson();
                        if (inFluffy.reservation && !this.isEditMode()) {
                            delete inFluffy.reservation;
                        }
                        API.setReservationToMcFluffy(
                            reservationIds,
                            inFluffy,
                            modify,
                            templateGroupIds,
                            isNewReservation,
                            (result) => {
                                resultSetReservationToMcFluffy = result;
                                done();
                            }
                        );
                    },
                    (done) => {
                        API.getReservations(reservationIds, (reservations) => {
                            resultGetReservations = reservations;
                            done();
                        });
                    },
                ],
                () => {
                    if (
                        resultSetReservationToMcFluffy.parameters[0] === null ||
                        reservationIds.length === 0 ||
                        resultGetReservations.length === 0
                    ) {
                        // We get no fluffy if we asked for a non-existant reservation ID
                        return callback(this, false);
                    }
                    if (modify && !resultSetReservationToMcFluffy.parameters[1]) {
                        return callback(this, false);
                    }
                    const fluffy = McFluffy.create(
                        resultSetReservationToMcFluffy.parameters[0],
                        resultGetReservations[0]?.objects
                    );
                    fluffy.rules = resultSetReservationToMcFluffy.parameters[2];
                    let newSelection = this.setFluffy(fluffy, false);
                    const mode = isWaitingList ? Selection.WAITING_LIST : Selection.DEFAULT;
                    newSelection = newSelection.immutableSet({ mode, length, clusterKind });
                    if (isNewReservation && !this.isGroupMode) {
                        newSelection = newSelection.setGroups([]);
                    }
                    return callback(
                        newSelection.immutableSet({ reservations: reservationIds }),
                        resultSetReservationToMcFluffy.parameters[1]
                    );
                }
            );
        });
        return null;
    }

    hasSnapshots(hasPrevious) {
        if (hasPrevious === undefined) {
            return this.snapshots.length > 0;
        }

        if (hasPrevious === true) {
            return this.currentSnapshot > 0;
        }

        return this.currentSnapshot < this.snapshots.length - 1;
    }

    getNextSnapshot() {
        return this.snapshots[this.currentSnapshot + 1];
    }

    getPreviousSnapshot() {
        return this.snapshots[this.currentSnapshot - 1];
    }
}

const getTimeLimit = function (entry: Entry) {
    const beginTimes = entry.startTimes.map((time) => time.getMts());
    const endTimes = entry.endTimes.map((time) => time.getMts());
    const sortedBeginTimes = [].concat(beginTimes);
    sortedBeginTimes.sort();
    const sortedEndTimes = [].concat(endTimes);
    sortedEndTimes.sort();
    return {
        begin: beginTimes,
        end: endTimes,
        first: sortedBeginTimes[0],
        last: sortedEndTimes[sortedEndTimes.length - 1],
    };
};
