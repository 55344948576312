export class Kind {
    number: number;
    name: string;
    mode: string;

    constructor(number: number, name: string, mode: string) {
        this.number = number;
        this.name = name;
        this.mode = mode.toLowerCase();
    }
}

const kinds = ["NULL", "RESERVATION", "AVAILABILITY", "INFO_RESERVATION"] as const;

type TKinds = (typeof kinds)[number];

type TTemplateKind = {
    [k in TKinds]: Kind;
} & {
    get: (number: number | string) => Kind;
    getFromString: (string: string) => Kind;
    equals: (kindA: Kind, kindB: Kind) => boolean;
};

const TemplateKind: TTemplateKind = {} as any;

TemplateKind.get = function (number: number | string) {
    const intValue = parseInt(number as string, 10) || 0;
    return TemplateKind[kinds[intValue]];
};

TemplateKind.getFromString = function (string) {
    const result = TemplateKind[string.toUpperCase()];
    if (result) {
        return result;
    }
    return TemplateKind.NULL;
};

TemplateKind.equals = function (kindA: Kind, kindB: Kind) {
    if (kindA === kindB) {
        return true;
    }

    if (kindA === null || kindA === undefined || kindB === null || kindB === undefined) {
        return false;
    }

    return kindA.number === kindB.number;
};

export default (function () {
    kinds.forEach((mode, index) => {
        TemplateKind[mode] = new Kind(index, `TEMPLATE_KIND_${mode}`, mode);
    });
    return TemplateKind;
})();
