import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import { Header } from "./Header";
import Locale from "../lib/Language";
import _ from "underscore";
import { Entry } from "./Entry";

export const WEEK_HEADER_WEEK_SIZE = 7;
export const WEEK_HEADER_VISIBLE_WEEKS = 4;

export class WeekHeader extends Header {
    start: any;
    weekdays: any[];
    numberOfWeeks: number;

    constructor(visibleValues, firstVisibleValue?, subheader?) {
        super(visibleValues || 1, firstVisibleValue, subheader, "WeekHeader");
        this.start = new MillenniumWeek(
            MillenniumDate.today(),
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
        this.weekdays = [Locale.firstDayOfWeek];
        this.numberOfWeeks = 52;
        this.size = 18;
    }

    indexOf(entry, onlyVisible) {
        let firstVisible = this.start;
        if (onlyVisible) {
            const firstVisibleDay = firstVisible
                .getStartOfWeek()
                .addWeeks(this.firstVisibleValue)
                .getMillenniumDate();
            firstVisible = new MillenniumWeek(
                firstVisibleDay,
                Locale.firstDayOfWeek,
                Locale.daysInFirstWeek
            );
        }
        let entryWeek;
        if (entry instanceof MillenniumWeek) {
            entryWeek = entry;
        } else {
            entryWeek = new MillenniumWeek(
                entry.startTimes[0],
                Locale.firstDayOfWeek,
                Locale.daysInFirstWeek
            );
        }
        return entryWeek.weeksBetween(firstVisible);
    }

    lastIndexOf(entry: Entry, onlyVisible: boolean) {
        let firstVisible = this.start;
        if (onlyVisible) {
            const firstVisibleDay = firstVisible
                .getStartOfWeek()
                .addWeeks(this.firstVisibleValue)
                .getMillenniumDate();
            firstVisible = new MillenniumWeek(
                firstVisibleDay,
                Locale.firstDayOfWeek,
                Locale.daysInFirstWeek
            );
        }
        let entryWeek;
        if (entry instanceof MillenniumWeek) {
            entryWeek = entry;
        } else {
            entryWeek = new MillenniumWeek(
                entry.endTimes[0],
                Locale.firstDayOfWeek,
                Locale.daysInFirstWeek
            );
        }
        return entryWeek.weeksBetween(firstVisible) + 1;
    }

    valueAt(index, onlyVisible = true) {
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            console.error(`Index out of bounds in WeekHeader.valueAt(${index})`);
            return null;
        }

        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values[index];
    }

    setLimits(limits) {
        const header = Header.prototype.setLimits.call(this, limits);

        const diff: any = {};
        const startDate = limits.getStartDate();
        const endDate = limits.getEndDate();
        diff.start = new MillenniumWeek(startDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        const endWeek = new MillenniumWeek(endDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        diff.numberOfWeeks = endWeek.weeksBetween(diff.start);

        diff.firstVisibleValue = header.firstVisibleValue;
        diff.firstVisibleValue =
            diff.firstVisibleValue +
            (diff.start.week() < this.start.week()
                ? this.start.weeksBetween(diff.start)
                : -this.start.weeksBetween(diff.start));
        if (diff.firstVisibleValue < 0) {
            diff.firstVisibleValue = 0;
        }

        if (
            diff.start.addWeeks(diff.firstVisibleValue + this.visibleValues).week() > endWeek.week()
        ) {
            diff.firstVisibleValue = diff.numberOfWeeks - this.visibleValues;
        }
        if (diff.firstVisibleValue < 0) {
            diff.visibleValues = this.visibleValues - Math.abs(diff.firstVisibleValue);
            diff.firstVisibleValue = 0;
        }

        return header.immutableSet(diff);
    }

    getInfo(value) {
        if (value.week() !== this.getVisibleValues()[0].week()) {
            return null;
        }

        const text: string[] = [];
        const weekdays = this.weekdays;
        const numDays = weekdays.length;
        let i;
        for (i = 0; i < numDays; i++) {
            text.push(SimpleDateFormat.format(weekdays[i], "EEE"));
        }
        return text.join(", ");
    }

    getLabel(value, size, index, onlyVisible, customWeekNames = []) {
        if (customWeekNames.length > 0) {
            const customWeek = getCustomWeek(value, customWeekNames);
            if (customWeek !== null) {
                if (size < Header.Label.L) {
                    return customWeek.getShortestName();
                }
                return customWeek.getLongestName();
            }
        }
        if (size < Header.Label.L) {
            return SimpleDateFormat.format(value.date, Locale.getDateFormat("date_f_w"));
        }

        return SimpleDateFormat.format(value.date, Locale.getDateFormat("date_f_yyyy_ww"));
    }

    isCurrent(week, currentDateTime) {
        if (!currentDateTime) {
            return false;
        }
        return new MillenniumWeek(
            currentDateTime.getMillenniumDate(),
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        ).isSameWeekAs(week);
    }

    getValues() {
        return getValues(this.start.date, this.numberOfWeeks);
    }

    getVisibleValues() {
        return getValues(
            new MillenniumDate(this.start.date.addWeeks(this.firstVisibleValue)),
            this.visibleValues
        );
    }

    containsDate(date) {
        const week = new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        return this.getValues().some((headerWeek) => headerWeek.isSameWeekAs(week));
    }

    getIndexOfDate(date) {
        const week = new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        let weekIndex = -1;
        this.getValues().forEach((headerWeek, index) => {
            if (headerWeek.isSameWeekAs(week)) {
                weekIndex = index;
            }
        });
        return weekIndex;
    }

    getSettings(providers) {
        const settings = Header.prototype.getSettings.call(this);
        const self = this;

        if (providers.weekday === true) {
            return settings;
        }

        settings.items.push({
            id: "weekdays",
            label: Locale.get("cal_list_weekdays"),
            type: "array",
            limit: WEEK_HEADER_WEEK_SIZE,
            get: self.getWeekdays.bind(self),
            set: (val) => self.immutableSet({ weekdays: val }),
        });

        return settings;
    }

    getWeekdays() {
        const labels = Locale.getWeekdayLabels();
        const values = MillenniumWeekday.getLocalizedWeekdayList(Locale.firstDayOfWeek).map(
            (val) => ({
                value: val,
                label: labels[val.getDay()],
            })
        );

        return values.map((item) =>
            _.extend(item, {
                selected: this.weekdays.some((weekday) => weekday === item.value),
            })
        );
    }

    toJSON() {
        const json = Header.prototype.toJSON.call(this);
        return _.extend(json, {
            dayProvider: true,
            kind: "week",
            size: this.size,
            weekdays: this.weekdays.map((weekday) => weekday.getRepresentation()),
        });
    }
}

const getCustomWeek = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name;
    }
    return null;
};

const getValues = function (startDate, limit) {
    const values: any[] = [];
    let i;
    let date = startDate;
    for (i = 0; i < limit; i++) {
        values.push(new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek));
        date = new MillenniumDate(date.getDayNumber() + WEEK_HEADER_WEEK_SIZE);
    }
    return values;
};
