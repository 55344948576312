import { MillenniumDateTime, MillenniumTime, MillenniumDate } from "@timeedit/millennium-time";
import _ from "underscore";
import { EntryKind } from "../lib/EntryConstants";
import { TEntryRules } from "../types/models/TEntry";
import { TimeEdit } from "../lib/TimeEdit";

import ReservationConstants from "../lib/ReservationConstants";
import { TimeConstants as TC } from "../lib/TimeConstants";
import { TTimeSlot } from "../types/api/TMcFluffy";
import { AvailabilityEntry } from "./AvailabilityEntry";

const EMPTY_PADDING = { padding: 0, paddingItems: [] };

const EMPTY_PADDINGS = { before: EMPTY_PADDING, after: EMPTY_PADDING };

export class Entry {
    startTimes: MillenniumDateTime[];
    endTimes: MillenniumDateTime[];
    text: string;
    layer: number;
    reservationids: number[];
    entrypropertyid: number;
    position: number;
    objects: never[];
    kind: number;
    incomplete: boolean;
    occupied: boolean;
    physical: boolean;
    membership: boolean;
    status: number;
    periods: any;
    isPartial: boolean;
    overlapCount: number;
    grouped: boolean;
    groups: number[];
    colorObjects: never[];
    clusterId: number;
    headerObjects: never[];
    colors: never[];
    conflictingObjects: never[];
    padding: {
        before: { padding: number; paddingItems: never[] };
        after: { padding: number; paddingItems: never[] };
    };
    isPadding: boolean;
    capacity?: number;
    capacities: never[];
    remainingCapacity?: number;
    capacityOrdering: undefined;
    capacityOrderings: never[];
    size: any;
    sizes?: any[];
    capacityReservationId?: number;
    capacityReservationIds?: number[];
    lock?: "soft";
    overlapGroup?: number;
    memberExceptionCount?: number;
    modifiable?: boolean;
    week_days?: number[];
    date?: MillenniumDate;
    clusterReservationIds?: number[];
    isSideBySide: boolean;
    overlapCountable?: boolean;
    dragType?: "move" | "copy" | "create";
    overlappingEntryIndex?: number;

    // Members that should not be set. We declare them so the typescript compiler wont complain.
    readonly isAvailability: undefined;

    constructor(startTimes: MillenniumDateTime[] = [], endTimes: MillenniumDateTime[] = []) {
        this.startTimes = _.asArray(startTimes);
        this.endTimes = _.asArray(endTimes);
        this.text = "";
        this.layer = 0;
        this.reservationids = [];
        this.entrypropertyid = 0;
        this.position = -1;
        this.objects = [];
        this.kind = EntryKind.NONE;
        this.incomplete = false;
        this.occupied = false;
        this.physical = true;
        this.membership = false;
        this.status = 0;
        this.periods = {};
        this.isPartial = false;
        this.overlapCount = 1;
        this.grouped = false;
        this.groups = [];
        this.colorObjects = [];
        this.clusterId = 0;
        this.headerObjects = [];
        this.colors = [];
        this.conflictingObjects = [];
        this.padding = EMPTY_PADDINGS;
        this.isPadding = false;
        this.capacity = undefined;
        this.capacities = [];
        this.remainingCapacity = undefined;
        this.capacityOrdering = undefined;
        this.capacityOrderings = [];
        this.size = undefined;
        this.sizes = undefined;
        this.capacityReservationId = undefined;
        this.capacityReservationIds = [];
        this.isSideBySide = false;
    }

    getLength(): number {
        return this.endTimes[0].getMts() - this.startTimes[0].getMts() || 0;
    }

    setLength(seconds: number): void {
        this.startTimes.forEach((start, index) => {
            this.endTimes[index] = start.addSeconds(seconds);
        });
    }
    isRequest(): boolean {
        return (
            this.status === ReservationConstants.STATUS.REQUESTED ||
            this.status === ReservationConstants.STATUS.REJECTED
        );
    }

    applyRules(
        rules: TEntryRules,
        length: number,
        usePreferred: boolean,
        useHighResolution: boolean,
        fixedProperty: "start" | "end" | "length" | null,
        isCreate: boolean,
        findNextShorterSlot?: boolean,
        requestedDuration?: number
    ): TTimeSlot[] {
        const slots: TTimeSlot[] = [];
        for (let i = 0; i < this.startTimes.length; i++) {
            const slot = applyRulesOnIndex.call(
                this,
                i,
                rules,
                length,
                usePreferred,
                useHighResolution,
                fixedProperty,
                isCreate,
                findNextShorterSlot,
                requestedDuration
            );
            if (slot !== null) {
                slots.push(slot);
            }
        }
        return slots.filter((slot) => !_.isNullish(slot));
    }

    getSubentries(headerObjects: any[] = [], splitOnDays = true): Entry[] {
        // Split entries by days
        if (this.startTimes.length === 0 || this.endTimes.length === 0) {
            return [];
        }
        let subentries: Entry[] = [this.clone()];

        if (splitOnDays) {
            const diffDays =
                this.endTimes[0].getMillenniumDate().getDayNumber() -
                this.startTimes[0].getMillenniumDate().getDayNumber();
            subentries = _.range(0, diffDays + 1).map((i) => {
                const subentry = this.clone();

                if (i > 0) {
                    subentry.startTimes = subentry.startTimes.map((time) =>
                        time.addDays(i).getStartOfDay()
                    );
                }
                if (i < diffDays) {
                    subentry.endTimes = subentry.startTimes.map((time) =>
                        time.getEndOfDay().addSeconds(-1)
                    );
                }

                return subentry;
            });
        } else {
            // Ensure entries ending at midnight are rendered as if they end one second before midnight
            subentries[0].endTimes = this.endTimes.map((time) => {
                if (time.isMidnight()) {
                    return time.addSeconds(-1);
                }
                return time;
            });
        }

        // Split entries by objects
        if (headerObjects && headerObjects.length > 0 && this.kind === EntryKind.INFO) {
            const objects = headerObjects?.reduce(
                (prevValue, currentValue) => prevValue.concat(currentValue.objects),
                []
            );
            subentries = _.flatten(
                subentries.map((subentry) =>
                    _.unique(objects).map((object) => _.extend({}, subentry, { objects: [object] }))
                )
            );
        }

        // Filter out zero-length entries
        return subentries.filter((subentry) => {
            const length = subentry.endTimes[0].getMts() - subentry.startTimes[0].getMts();
            return length > 0;
        });
    }
    static equals(e1: Entry, e2: Entry): boolean {
        return (
            _.every(e1.startTimes, (time, i) => time.equals(e2.startTimes[i])) &&
            _.every(e1.endTimes, (time, i) => time.equals(e2.endTimes[i])) &&
            _.isEqual(e1.objects, e2.objects)
        );
    }

    equals(entry: Entry): boolean {
        return Entry.equals(this, entry);
    }

    static timeEquals(e1: Entry, e2: Entry): boolean {
        return (
            _.every(e1.startTimes, (time, i) => time.equals(e2.startTimes[i])) &&
            _.every(e1.endTimes, (time, i) => time.equals(e2.endTimes[i]))
        );
    }

    static isBlue(entry: Entry | AvailabilityEntry): boolean {
        return (
            entry.kind === EntryKind.COMPLETE ||
            entry.kind === EntryKind.RESERVATION ||
            entry.kind === EntryKind.GROUP_COMPLETE ||
            entry.kind === EntryKind.GROUP
        );
    }

    static isReservation(entry: Entry): boolean {
        return _.contains(
            [
                EntryKind.COMPLETE,
                EntryKind.RESERVATION,
                EntryKind.OBSTACLE,
                EntryKind.GROUP_COMPLETE,
                EntryKind.GROUP,
                EntryKind.OBSTACLE_GROUP,
            ],
            entry.kind
        );
    }

    overlaps(otherEntry: Entry): boolean {
        return (
            (_.some(this.startTimes, (startTime) =>
                _.some(otherEntry.startTimes, (otherStart) => startTime.mts <= otherStart.mts)
            ) &&
                _.some(this.endTimes, (endTime) =>
                    _.some(otherEntry.startTimes, (otherStart) => endTime.mts >= otherStart.mts)
                )) ||
            (_.some(this.startTimes, (startTime) =>
                _.some(otherEntry.endTimes, (otherEnd) => startTime.mts <= otherEnd.mts)
            ) &&
                _.some(this.endTimes, (endTime) =>
                    _.some(otherEntry.endTimes, (otherEnd) => endTime.mts >= otherEnd.mts)
                ))
        );
    }

    hasSize(): boolean | undefined {
        return (
            (this.size !== undefined && this.size !== null) || (this.sizes && this.sizes.length > 0)
        );
    }

    isInside(otherEntry: Entry): boolean {
        return (
            _.every(this.startTimes, (startTime) =>
                _.every(otherEntry.startTimes, (otherStart) => startTime.mts >= otherStart.mts)
            ) &&
            _.every(this.endTimes, (endTime) =>
                _.every(otherEntry.endTimes, (otherEnd) => endTime.mts <= otherEnd.mts)
            )
        );
    }

    clone(): Entry {
        return Object.keys(this).reduce(
            (entry, key) => _.extend(entry, { [key]: this[key] }),
            new Entry()
        );
    }

    isLocked(unlockedReservations: number[]): boolean {
        if (!unlockedReservations || unlockedReservations.length === 0) {
            return false;
        }
        return !_.some(this.reservationids, (id) => unlockedReservations.indexOf(id) !== -1);
    }

    // For new lock functionality - should of course be isLocked, and probably a property instead of a function
    // Rename - or see if we can remove - above lock function when we get closer to release.
    hasLock(): boolean {
        return false;
    }

    hasPadding(): boolean {
        return (
            this.padding &&
            (this.padding.before !== EMPTY_PADDING || this.padding.after !== EMPTY_PADDING)
        );
    }

    static create(data: any): Entry {
        const entry = new Entry();
        entry.startTimes = toMillenniumDateTime(data.begins || data.begin);
        entry.endTimes = toMillenniumDateTime(data.ends || data.end);
        entry.groups = (data.group ? [data.group] : data.groups) || [];
        entry.text = data.text || "";
        entry.layer = data.layer || 0;
        entry.reservationids =
            data.reservationids || (data.reservationid ? [data.reservationid] : []);
        entry.entrypropertyid = data.entrypropertyid;
        entry.objects = data.objects || [];
        entry.headerObjects = _.filter(
            entry.objects,
            // eslint-disable-next-line no-shadow
            (etr, index) =>
                data.isheaderobjects &&
                data.isheaderobjects.length > 0 &&
                data.isheaderobjects[index] === true
        );
        entry.colorObjects = data.colorobjects || [];
        entry.colors = data.colors || [];
        entry.conflictingObjects = data.conflictingObjects || [];
        entry.lock = data.lock || undefined;
        entry.capacity = data.capacity || undefined;
        if (data.capacity === 0) {
            entry.capacity = 0;
        }
        entry.capacities = data.capacities || [];
        entry.remainingCapacity = data.remainingCapacity || undefined;
        if (data.remainingCapacity === 0) {
            entry.remainingCapacity = 0;
        }
        entry.capacityOrdering = data.capacityOrdering || undefined;
        entry.capacityOrderings = data.capacityOrderings || [];
        entry.sizes = data.sizes || undefined;
        entry.size = data.size || undefined;
        if (data.size === 0) {
            entry.size = 0;
        }
        entry.capacityReservationId = data.capacityReservationId || undefined;
        [
            "incomplete",
            "kind",
            "occupied",
            "physical",
            "membership",
            "status",
            "position",
            "modifiable",
            "readable",
            "overlapCountable",
            "grouped",
            "obstacleGrouped",
            "complete",
            "hideOnDrag",
            "clusterId",
            "periodIds",
        ].forEach((prop) => {
            entry[prop] = data[prop] || this.getDefaults(entry.entrypropertyid)[prop];
            if (prop === "status" && _.isObject(entry.status)) {
                entry.status = (entry.status as any).status;
            }
        });

        if (Entry.isReservation(entry) && !entry.modifiable) {
            entry.isPartial = true;
        }
        entry.overlapCount = data.overlap || 0;
        entry.overlapGroup = data.overlapGroup || 0;
        entry.memberExceptionCount = data.memberExceptionCount || 0;
        entry.padding = Object.assign({}, EMPTY_PADDINGS);
        if (data.paddingBefore) {
            entry.padding.before = data.paddingBefore;
        }
        if (data.paddingAfter) {
            entry.padding.after = data.paddingAfter;
        }
        entry.isPadding = data.isPadding || false;
        entry.capacityReservationIds = data.capacityReservationIds || [];
        return entry;
    }

    static getDefaults(id: number) {
        return _.find(TimeEdit.entryPropertyDefinitions, (item) => item.id === id);
    }
}

const getSlotsFor = function (absoluteWeekday: number, slots: TTimeSlot[]) {
    return _.filter(slots, (slot) => slot.week_days.indexOf(absoluteWeekday) !== -1);
};

const findBestSlot = function (
    startTime: number,
    endTime: number,
    fixedProperty: "start" | "end" | "length" | null,
    slots: TTimeSlot[]
) {
    let filterFn = (slot: TTimeSlot) =>
        slot.start_time <= startTime && _.some(slot.end_times, (end) => startTime < end);
    if (fixedProperty === "start") {
        filterFn = (slot) =>
            slot.start_time === startTime && _.some(slot.end_times, (end) => startTime < end);
    }
    if (fixedProperty === "end") {
        filterFn = (slot) =>
            slot.start_time >= startTime && _.some(slot.end_times, (end) => startTime > end);
    }
    const applicableSlots: TTimeSlot[] = _.sortBy(
        _.filter(slots, filterFn),
        "start_time"
    ).reverse();
    return applicableSlots[0]; // || getDefaultSlot(slots, fixedProperty, startTime);
};

const findBestEndTime = function (
    startSeconds: number,
    endSeconds: number,
    endTimes: number[],
    fixedProperty: "start" | "end" | "length" | null,
    findNextShorterSlot?: boolean
) {
    // End time should also be after the found start time if end isn't fixed
    let closestTime = endTimes[0];
    if (fixedProperty === "end") {
        endTimes.forEach((et) => {
            if (et > closestTime && et <= startSeconds && et < endSeconds) {
                closestTime = et;
            }
        });
        const lastTime = endTimes[0];
        if (endSeconds < lastTime) {
            closestTime = lastTime;
        }
    } else {
        closestTime = findNextShorterSlot ? endTimes[0] : endTimes[endTimes.length - 1];
        endTimes.forEach((et) => {
            if (findNextShorterSlot === true) {
                if (et > closestTime && et <= endSeconds && et > startSeconds) {
                    closestTime = et;
                }
            } else {
                if (et < closestTime && et >= endSeconds && et > startSeconds) {
                    closestTime = et;
                }
            }
        });
        const lastTime = endTimes[endTimes.length - 1];
        if (endSeconds > lastTime) {
            closestTime = lastTime;
        }
    }
    return closestTime;
};

const applySlotRulesOnIndex = function (
    this: Entry,
    index: number,
    startSeconds: number,
    endSeconds: number,
    rules: TEntryRules,
    fixedProperty: "start" | "end" | "length" | null,
    isCreate: boolean,
    usePreferred: boolean,
    fluffyLength: number,
    findNextShorterSlot?: boolean,
    requestedDuration?: number
) {
    const possibleSlots = getSlotsFor(
        this.startTimes[index].getMillenniumDate().getDay(),
        rules.time_slots.start_times
    );
    const bestSlot = findBestSlot(startSeconds, endSeconds, fixedProperty, possibleSlots);
    if (bestSlot) {
        let startTime = bestSlot.start_time;
        let endTime = endSeconds;
        if (fixedProperty === "length" && requestedDuration) {
            endTime = startTime + requestedDuration;
        }

        endTime = findBestEndTime(
            startTime,
            endTime,
            bestSlot.end_times || rules.time_slots.end_times,
            fixedProperty,
            findNextShorterSlot
        );
        if (
            usePreferred &&
            isCreate &&
            bestSlot.default_end_time &&
            bestSlot.default_end_time > endTime &&
            !requestedDuration
        ) {
            endTime = bestSlot.default_end_time;
        }
        if (startTime > endTime) {
            const tmpTime = startTime;
            startTime = endTime;
            endTime = tmpTime;
        }
        if (fixedProperty === "length") {
            let length =
                usePreferred && isCreate
                    ? fluffyLength
                    : this.endTimes[index].getMts() - this.startTimes[index].getMts();
            if (isCreate && endTime - startTime > length) {
                length = endTime - startTime;
            }
            this.startTimes[index] = new MillenniumDateTime(
                this.startTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime)
            );
            this.endTimes[index] = new MillenniumDateTime(
                this.endTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime + length)
            );
            return bestSlot;
        }
        if (isCreate || fixedProperty !== "start") {
            this.startTimes[index] = new MillenniumDateTime(
                this.startTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime)
            );
        }
        if (isCreate || fixedProperty !== "end") {
            this.endTimes[index] = new MillenniumDateTime(
                this.endTimes[index].getMillenniumDate(),
                new MillenniumTime(endTime)
            );
        }
    }
    return bestSlot;
};

const applyRulesOnIndex = function (
    this: Entry,
    index: number,
    rules: TEntryRules,
    fluffyLength: number,
    usePreferred: boolean,
    useHighResolution: boolean,
    fixedProperty: "start" | "end" | "length" | null,
    isCreate: boolean,
    findNextShorterSlot?: boolean,
    requestedDuration?: number
) {
    let startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
    let endSeconds = this.endTimes[index]
        ? this.endTimes[index].getMillenniumTime().getTimeNumber()
        : null;
    const length = this.endTimes[index]
        ? this.endTimes[index].getMts() - this.startTimes[index].getMts()
        : 0;
    if (rules.time_slots_only) {
        return applySlotRulesOnIndex.call(
            this,
            index,
            startSeconds,
            endSeconds,
            rules,
            fixedProperty,
            isCreate,
            usePreferred,
            fluffyLength,
            findNextShorterSlot,
            requestedDuration
        );
    }
    let diff;
    const step = useHighResolution && rules.minimum_step ? rules.minimum_step : rules.major_step;
    const startStep =
        useHighResolution && rules.minimum_step ? rules.minimum_step : rules.start_step;

    if (!fixedProperty && usePreferred) {
        // eslint-disable-next-line no-param-reassign
        fixedProperty = "start";

        diff = startSeconds % startStep;
        if (diff !== 0) {
            diff = 0 - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
    }

    if (!fixedProperty) {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            diff = 0 - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
        if (this.endTimes[index].getMts() < this.startTimes[index].getMts() + step) {
            this.endTimes[index] = this.startTimes[index].addSeconds(step);
        }

        return null;
    }

    // Start step
    if (fixedProperty !== "end") {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            if (startStep === TC.SECONDS_PER_DAY) {
                diff = 0 - diff;
            } else {
                // eslint-disable-next-line no-magic-numbers
                diff = diff < startStep / 2 ? 0 - diff : startStep - diff;
            }
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
    }

    if (fixedProperty === "length") {
        let ln = length;
        if (ln === 0) {
            ln = fluffyLength > 0 ? fluffyLength : rules.pref_length;
        }
        this.endTimes[index] = this.startTimes[index].addSeconds(ln);
        return null;
    }

    diff = (endSeconds - startSeconds) % step;
    if (diff !== 0) {
        // eslint-disable-next-line no-magic-numbers
        diff = diff < step / 2 ? 0 - diff : step - diff;
        if (fixedProperty === "end") {
            this.startTimes[index] = this.startTimes[index].addSeconds(-diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        } else {
            this.endTimes[index] = this.endTimes[index].addSeconds(diff);
            endSeconds = this.endTimes[index].getMillenniumTime().getTimeNumber();
        }
    }
    // Minimum length
    if (usePreferred) {
        const prefLength = fluffyLength > 0 ? fluffyLength : rules.pref_length;
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-prefLength);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(prefLength);
        }
    } else if (
        this.endTimes[index] &&
        this.endTimes[index].getMts() - this.startTimes[index].getMts() < rules.min_length
    ) {
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-rules.min_length);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(rules.min_length);
        }
    }

    // Maximum length
    if (
        this.endTimes[index] &&
        this.endTimes[index].getMts() - this.startTimes[index].getMts() > rules.max_length
    ) {
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-rules.max_length);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(rules.max_length);
        }
    }

    startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();

    // Start step
    if (fixedProperty === "end") {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            // eslint-disable-next-line no-magic-numbers
            diff = diff < startStep / 2 ? 0 - diff : startStep - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
        }
    }
    return null;
};

const toMillenniumDateTime = function (timestamp: number[] | number) {
    if (!Array.isArray(timestamp)) {
        // eslint-disable-next-line no-param-reassign
        timestamp = [timestamp];
    }
    return timestamp.map((tm) => new MillenniumDateTime(tm));
};
